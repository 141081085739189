.apexcharts-canvas {
  height: 100% !important;
}

.apexcharts-tooltip {
  background-color: #fff !important;
  border-radius: 8px !important;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;

  padding: 12px 8px;
  position: relative;
  /* Position relative for pseudo-element */
}

.apexcharts-tooltip::before {
  content: '';
  position: absolute;
  bottom: -10px;
  /* Position the arrow 10px below the tooltip */
  left: 50%;
  /* Center the arrow horizontally */
  transform: translateX(-50%);
  /* Adjust horizontal positioning */
  border-width: 10px;
  /* Size of the arrow */
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  /* Arrow color and transparency */
  z-index: 10;
}

.apexcharts-tooltip-title {
  background-color: #fff !important;
  background: none !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #667085 !important;
  padding: 0 !important;
  margin: 0 0 4px 0 !important;
}

.apexcharts-tooltip-series-group {
  padding: 0 !important;
  margin: 0 !important;
}

.apexcharts-tooltip-marker {
  width: 8px !important;
  height: 8px !important;
}

.apexcharts-tooltip-text-y-label,
.apexcharts-tooltip-text-y-value {
  font-family: var(--font-manrope) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: #1d2939 !important;
}

.apexcharts-tooltip-text-y-value {
  font-weight: 600 !important;
}

.apexcharts-tooltip-y-group {
  padding: 0 !important;
}

.apexcharts-tooltip-series-group.apexcharts-active:not(:last-child) {
  margin-bottom: 6px !important;
}

.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #667085 !important;
}
